

@font-face {
	font-family: 'Tw Cen MT';
	src: url('#{$font-path}/Tw-Cen-MT.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Tw Cen MT';
	src: url('#{$font-path}/Tw-Cen-MT-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Tw Cen MT';
	src: url('#{$font-path}/Tw-Cen-MT-Bold-Italic.otf') format('opentype');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Tw Cen MT';
	src: url('#{$font-path}/Tw-Cen-MT-Italic.otf') format('opentype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
  font-family: "cob";
  src: url('#{$font-path}/cob.ttf') format("truetype");
  font-style: normal;
  font-weight: 400;
}


h1, .h1 {
	line-height: (60px / $font-size-h1);
	
	@include media(xs) {
		font-size: 34px;
		line-height: (40px / 34px);
	}
	
	&.light {
		font-weight: 300;
	}
}
h2, .h2 {
	line-height: (48px / $font-size-h2);
	
	@include media(xs) {
		font-size: 34px;
		line-height: (40px / 34px);
	}
}
h3, .h3 { line-height: (32px / $font-size-h3); }


h1, .h1,
h2, .h2,
h3, .h3 {
	text-transform: uppercase;
}