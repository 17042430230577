.navbar {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 0;
	border-bottom: 0;
}

.navbar-nav {
	> li {
		> a {
			color: #000;
			font-size: 20px;
			padding-top: 0px;
			border-bottom: 3px solid #FFF; 
			@include media(md) {
				padding-left: 7px;
				padding-right: 7px;
				margin: 10px 8px;
			}
			
			@include media(sm) {
				padding-left: 5px;
				padding-right: 5px;
			}
			&:hover, &:focus {
				border-bottom: 3px solid #000;
				border-color: #000 !important;
				transition: all 0.25s linear;
				-webkit-transition: all 0.25s linear;
			}
		}
	}
}

.navbar-brand {
	padding: 0;
	padding-top: 22px;
	>img {
		@media(min-width: $screen-sm) {
			 
			display: block;
			margin-top: -27px;
		}
	}
}

.social-icons {
	
	clear: both;
	ul {
		padding-left: 10px;
	}
}

.navbar-social {
	font-size: 18px;
	margin-left: 10px;
	float: right;
	margin-top: 35px;
	@include media(sm) { margin-left: 0; }
	> li {
		
		float: left;
		list-style: none;
		
		> a {
			
			color: #000;
			padding-left: 5px;
			padding-right:  5px;
		}
	}
}

.navbar-container {
	background-color: #fff;
	position: relative;
	z-index: 2;
	.eye {
	  height: 17px;
	  width: 32px;
	  margin-right: 7px;
	  margin-left: 7px;
	  cursor: pointer;
	  display: inline-block;
	  background-size: 32px 17px;
	  background-image: url(http://bwa.klienci.052b.com/web/wp-content/themes/theme//dist/images/icon-eye.svg);
	  vertical-align: middle;
	}
	.eye:hover {
	  background-image: url(http://bwa.klienci.052b.com/web/wp-content/themes/theme//dist/images/icon-eye2.svg);
	}
	@media (max-width: 1199px) {
	  .navbar-default .right-side .eye {
	    margin-right: 10px;
	  }
	}
	.changeText {
		display: inline-block;
		a {
			color: red;
			padding: 2px 1px;
			font-weight: 400;
			&:hover, &:focus {
				 color: #000;
				 text-decoration: none;
			}
			
			&:nth-child(1) {
				font-size: 18px;
			}
			&:nth-child(2) {
				font-size: 15px;
			}
			&:nth-child(3) {
				font-size: 13px;
			}
		}
		.active {
				font-weight: 700;
			}
	}
}


.navbar>.container-fluid .navbar-brand, .navbar>.container .navbar-brand {
    margin-left: -15px;
}
.navbar-toggle {
	width: 42px;
	height: 30px;
	position: relative;
	margin-right:  0px;
	margin-top:  0px;
	margin-bottom: 15px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	
 
	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #000;
		border-radius: 0px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
	}
  span:nth-child(1) {
	  top: 0px;
	  -webkit-transform-origin: left center;
	  -moz-transform-origin: left center;
	  -o-transform-origin: left center;
	  transform-origin: left center;
	}
  span:nth-child(2) {
	  top: 13px;
	  -webkit-transform-origin: left center;
	  -moz-transform-origin: left center;
	  -o-transform-origin: left center;
	  transform-origin: left center;
	}
	  span:nth-child(3) {
	  top: 26px;
	  -webkit-transform-origin: left center;
	  -moz-transform-origin: left center;
	  -o-transform-origin: left center;
	  transform-origin: left center;
	}
}

.navbar-toggle.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 2px;
  left: 3px;
}

.navbar-toggle.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.navbar-toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 30px;
  left: 2px;
}
 


.box-mark {
	.image {
		line-height: 192px;
		text-align: center;
		.svg {
			fill: #747474;
			display: inline-block;
			vertical-align: middle;
			transition: all 100ms ease-in-out;
		}
	}
	
	
	&:hover,
	&:focus {
		.image {
			.svg {
				fill: #ffffff;
			}
		}
	}
}



.navbar-toggle .icon-bar {
	background-color: #000;
}


.navbar-brand {
	padding-top: 0;
	
	>img {
		@include media(md) { width: 180px; }
		@include media(sm) { width: 150px; }
	}
}

.navbar-header {
	@include media(sm) {
		float: none;
	}
}

.navbar-nav>li>a { 
	padding-bottom: 5px;
	
}
.navbar-nav {
	.open {
		.dropdown-toggle {
			border-bottom: 3px solid #000;
			border-color: #000 !important;
		}
		
	}
	 
	.dropdown-toggle:hover, .dropdown-toggle:focus {
		border-bottom: 3px solid #000;
		border-color: #000 !important;
		transition: all 0.25s linear;
		-webkit-transition: all 0.25s linear;
	}
}
.dropdown-menu { 
	box-shadow: none;
	border: 0px;
	ul {
		list-style-type: none;
		
	}
	> li {
		display: table-cell;
		> a {
			color: #000;
			border-bottom: 3px solid #FFF;
			border-color: #FFF !important;
			&:hover, & focus {
				color: #000;
				border-bottom: 3px solid #000;
				border-color: #000 !important;
				transition: all 0.25s linear;
				-webkit-transition: all 0.25s linear;
			}
		}
		
	}
	>.active>a {
		background: transparent;
		border-bottom: 3px solid #000;
		color: #000;
	}
}
 .dropdown-menu li:hover .sub-menu {
	@media(min-width: $screen-sm) {
		 visibility: visible;
	}
   
}
.current_page_parent a{
	border-bottom: 3px solid #000;
	border-color: #000 !important;
}
.dropdown:hover .dropdown-menu {
   
	@media(min-width: $screen-sm) {
		 display: block;
	}
}
.nav>li>a {
    margin: 10px 15px;
	padding: 0px;
	@include media(md) {
				
				margin: 10px 6px;
			}

}
.dropdown-menu>li>a {
	padding: 0px;
	margin: 3px 20px;
}
@media(max-width: $screen-sm) {
	.navbar-nav .open .dropdown-menu>li>a
	{
		padding: 0px;
		margin: 3px 15px;
	}
}
body {
	.contrast {
		a {
			color: red !important;
		}
	}
}

// clearfix