
body {
 
  font-size: 14px;

}

body > #lightbox {
	
	display: none;
}

.section {
	
	&.dark {
		background-color: #434343;
		color: #fff;
	}
	
	.col {
		&-1 {
			@include make-xs-column(12);
			@include make-md-column(8);
			@include make-md-column-offset(2);
		}
		&-2 {
			@include make-md-column( 12/2 );
		}
		&-3 {
			@include make-sm-column( 12/3 );
		}
		&-4 {
			@include make-sm-column( 12/4 );
		}
		
		@include media-up(md) {
			&-text {
				padding-right: calc( ( 100% / 12 ) + 15px );
			}
		}
		
	}
	
	&.reverse {
		.col {
			@include media-up(md) {
				&-text {
					@include make-sm-column-push(6);
					padding-right: 15px;
					padding-left: calc( ( 100% / 12 ) + 15px );
				}
				&-image {
					@include make-sm-column-pull(6);
				}
			}
		}
	}
	

	
}




.section-bs-image {
	.col-text {
		@include media-up(md) {
			display: flex;
			flex: 0 1 auto;
			flex-wrap: wrap;
			align-content: space-between;
		}
	}
	
}
	
	
.section-big_image {
	
	overflow: hidden;
	
	
	.image {
		@include media-up(md) {
			position: absolute;
			left: 0;
		}
		@include media-down(sm) {
			max-width: 100%;	
		}
	}
	
	.col {
		@include media-up(md) { min-height: 690px; }
		&-text {
			@include media-up(md) { 
				padding-top: 94px;
				padding-bottom: 80px;
			}
			@include media-down(sm) { 
				padding-top: (94px/2);
				padding-bottom: (94px/2);
			}
		}
		
		&-image {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	@include media-up(md) {
		&.reverse {
			.image {
				position: absolute;
				left: inherit;
				right: 0;
			}
		}
	}
}

.section-small_image {
	
	.col {
		@include media(md) {
			min-height: 585px;
		}
		@include media(md) {
			min-height: 485px;
		}
		
		&-text {
			padding-top: (94px/2);
			padding-bottom: (94px/2);
		}
		&-image {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.image {
		@include img-responsive;
		@include center-block;
	}
}


.section-full_image {
	color: #fff;
	
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 14em;
	padding-bottom: 14em;
	display: flex;
	align-items: center;
	
	.col {
		text-align: center;
	}
	
	.title {
		margin-bottom: 1em;
	}
	
}

.section-boxs {
	background-color: #434343;
	color: #fff;
	padding-top: 5em;
	padding-bottom: 6em;
	counter-reset: boxs-counter;
	
	
	.col {
		counter-increment: boxs-counter;
		text-align: center;
	}
	
	.box {
		
		.title {
			min-height: (32px / $font-size-h3) * 2em;
		}
		
		&:before {
			content: counter(boxs-counter);
			display: block;
			@extend .h1;
			margin-bottom: 0;
		}
	}
}



.section-slider {
	color: #fff;

	& {
		text-align: center;
		
		p {
			font-size: 26px;
			.bigger {
				font-size: calc(26px*80%);
			}
		}
		
	}

	.flexslider {
		margin: 0;
		background: transparent;
		border: 0;
		position: relative;
		zoom: 1;
		border-radius: 0;
		box-shadow: none;
		
		.slides {
			
			.slide {
				background-position: center top;
				background-repeat: no-repeat;
				background-size: cover;
				@include media(lg) { min-height: 600px; }
				@include media(md) { min-height: 500px; }
				@include media-down(sm) { min-height: 380px; }
				
				
				display: flex;
				align-items: center;
			}
		}
	}	
}

.flexslider {
	margin-bottom: 0;
	padding: 0;
	border: 0;
	background: transparent;
	border-radius: 0;
}

.flex-control-nav {
	display: none !important;
	bottom: 0;
	z-index: 100;
	
	> li {
		> a {
			background-color: transparent;
			border: 1px solid #fff;
			box-shadow: none;
			&.flex-active {
				background-color: #fff;
			}
		}
	}
}

.main-content {
	
	font-family: Tw Cen MT;
	font-size: 18px;
	font-weight: 300;
	text-align: center;
	
	a {
		color: #000;
		
		.read-more {
			
			color: #5eff00;
			font-weight: bold;
		}
	}
	
	h4 {
		
		font-size: 28px;
		font-weight: bold;
		color: #000;
	} 
	
	.box {
		
		margin-bottom: 40px;
	}
}

.featured-exhibition {
	 
	
	.short-description {
		 
		font-size: 18px;
		text-align: center;
		a  {
			color: #000;
			text-decoration: none;
		}
	}
	
	.description {
		
		font-size: 22px;	
		text-align: center;
		line-height: 24px;
	}
	
	&-background {
		
		background-repeat: none;
		background-size: cover;
		width: 100%; 
		
		font-size: 46px;
		font-weight: bold;
		line-height: 48px;
		
		position: relative;
		
	}
	
	&-title {
		
		position: absolute;
		top: -80px;
		left: -40px;
		
		text-align: center; 
	}
	
	&-link {
		
		margin-top: 18px;
		font-size: 22px;
		text-align: center;
		
		
		.read-more {
			
			color: #5eff00;
			font-weight: bold;
		}
	}
	
	
}


.separator {
		
	margin-top: 40px;
	border-bottom: 1px #d5d5d5 solid;
	margin-bottom: 40px;
}

.section-text {
	padding-top: 80px;
	padding-bottom: 100px;
}


.section-gallery {

}



.container-article {
	
	font-family: Tw Cen MT;
	font-size: 18px;
	font-weight: 300;
	text-align: left; 
	
	&-photo {
		
		background-repeat: none;
		background-size: cover;
		width: 100%;
		min-height: 475px;
		
		font-size: 46px;
		font-weight: bold;
		line-height: 48px;
		
		position: relative;
		
	}
	
	&-title {
		
		position: absolute;
		top: -40px;
		left: -40px;
		
		text-align: center; 
	}
}

.see-also {
	
	&-bar {
		
		font-size: 21px;
		text-align: center;
		margin-top: 80px;
		margin-bottom: 22px;
		border-top: 1px #858585 solid;
		border-bottom: 1px #858585 solid;
		padding: 5px 0;
	}
	
	&-photo {
		
		background-repeat: none;
		background-size: cover;
		width: 100%;
		min-height: 230px;
	}
	
	h4 {
		font-size: 26px;
		font-weight: bold;
		text-align: center;
		color: #000;
		margin-bottom: 20px;
		min-height: 60px;
		
		@media(max-width: $screen-sm)
		{
			min-height: auto;
			padding-top: 50px;
			
		}
	} 
	
	a {
		
		color: #000;
	}
}
.gallery {
	text-align: left;
	img {
		width: 19%;
	}
}

a {
   outline: 0;
   color: #000 !important;
}
body.contrast a.read-more {
	color: #878d96 !important;
		
	
}
body.contrast .ui-state-active {
	background-color: #878d96 !important;
}
html {
  font-size: 62.5% !important;
  /* Sets up the Base 10 stuff */
}
html.bigger{ 
	.featured-exhibition-title2 {
		font-size: calc(36px*1.1);
		line-height: 40px;
  	}
  	.navbar-nav>li>a {
	  	font-size: calc(20px*1.1);
  	}
  	.featured-exhibition .short-description a {
	  	font-size: calc(18px*1.1);
  	}
  	.main-content {
	  	font-size: calc(18px*1.1);
  	}
  	h4 {
	  	font-size: calc(18px*1.1);
  	}
  	.main-content h4 {
	  	font-size: calc(28px*1.1);
  	}
  	
  	.sidebar-box {
	  	font-size: calc(16px*1.1);
  	}
  	.sidebar-box h4 {
	  	font-size: calc(28px*1.1);
  	}
  	.team-title {
	  font-size: calc(36px*1.1);	
  	}
  	.address {
	  	font-size: calc(16px*1.1);	
  	}
  	.footer-box {
	  	font-size: calc(14px*1.1);	
  	}
  	.copyright {
	  	font-size: calc(14px*1.1);	
  	}
  	.footer .back-to-top {
	  	font-size: calc(30px*1.1);
  	}
  	.see-also-box h4 {
	  	font-size: calc(26px*1.1);
  	}
  	.container-article p{
	  	font-size: calc(18px*1.1);
  	}
  	.separator {
		margin-top: 5px !important;
	}
	.navbar-collapse {
		padding-left: 0 !important;
	}
}

html.smaller{ 
	.featured-exhibition-title2 {
		font-size: calc(36px*0.9);
  	}
  	.navbar-nav>li>a {
	  	font-size: calc(20px*0.9);
  	}
  	.featured-exhibition .short-description a {
	  	font-size: calc(18px*0.9);
  	}
  	.main-content {
	  	font-size: calc(18px*0.9);
  	}
  	h4 {
	  font-size: calc(18px*0.9);
  	}
  	.main-content h4 {
	  	font-size: calc(28px*0.9);
  	}
  
  	.sidebar-box {
	  	font-size: calc(16px*0.9);
  	}
  	.sidebar-box h4 {
	  	font-size: calc(28px*0.9);
  	}
  	.team-title {
	  font-size: calc(36px*0.9);	
  	}
  	.address {
	  	font-size: calc(16px*0.9);	
  	}
  	.footer-box {
	  	font-size: calc(14px*0.9);	
  	}
  	.copyright {
	  	font-size: calc(14px*0.9);	
  	}
  	.footer .back-to-top {
	  	font-size: calc(30px*0.9);
  	}
  	.see-also-box h4 {
	  	font-size: calc(26px*0.9);
  	}
  	.container-article p{
	  	font-size: calc(18px*0.9);
  	}
}
