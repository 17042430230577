.btn {
	font-size: 18px;
	border-radius: 0;
	border: 0;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0.77em 1.11em 0.61em;
	transition: all 200ms ease-in-out;
	
	&:after {
		@extend .#{$fa-css-prefix};
		content: $fa-var-long-arrow-right;
		font-size: 14px;
		margin-left: 1em;
	}
	&-pin:after {
		content: $fa-var-map-marker;
	}
	
	
	&-beige {
		background-color: #f2dfb6;
		color: #000000;
		
		&:hover,
		&:focus {
			background-color: #dabd80;
		}
	}
	&-transparent {
		background-color: transparent;
		color: #010101;
	}
	
	&-no-padding {
		padding-left: 0;
		padding-right: 0;
	}
	
	&-base {
		&:after {
			display: none;
		}
		border: 0;
		padding: 0;
		line-height: inherit;
		background-color: transparent;
		border-radius: 0;
		font-size: inherit;
		&:focus,
		&:hover {
			outline: none;
		}
	}
	
	&-clip {
		color: #fff;
		background-color: transparent;
		font-size: 14px;
		&:hover {
			color: darken(#fff,15%);
		}
	}
	
	&-white {
		background-color: #fff;
		color: #000000;
	}
	&-add {
		background: #9b9b9b;
		color: #fff;
		width: 2em;
		height: 2em;
		text-align: center;
		line-height: 2em;
	}
}

.relative {
	position: relative;
}


.scroll-top {
	&-wrapper {
		position: absolute;
		right: 0;
		z-index: 10;
	}
	text-transform: uppercase;
	font-weight: 700;
	color: #9e9e9e;
	
	transform: rotate(-90deg);
	transform-origin: right bottom 0; 
}





.compare {
	&:before {
		@extend .fa;
		content: $fa-var-plus;
	}
	
	&.added {
		&:before {
			content: $fa-var-minus;
		}
	}
}



