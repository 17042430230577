.modal-content {
	box-shadow: none;
	border-radius: 0;
	border: 0px;
	
}

.modal-header {
	padding-top: 0;
	padding: 0;
	font-size: 16px;
	
	border-bottom: 0;
	
	.title {
		font-size: 1em;
		margin-top: 1.7em;
		margin-bottom: 1em;
	}
	.position {
		margin-bottom: 1em;
	}
	.phone {
		font-size: 1.1em;
		font-weight: 700;
		margin-bottom: 1em;
		a {	color: #000; }
		
		.fa {
			vertical-align: middle;
			font-size: 0.85em;
			margin-right: 1em;
		}
	}
	
	.close {
		margin-top: 1px;
		margin-right: 5px;
	}
}

.modal-body {
	padding-top: 15px;
	padding-bottom: 15px;
}

