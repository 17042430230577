
.sidebar {
	
	font-family: Tw Cen MT;
	font-size: 18px;
	font-weight: 300;
	text-align: center;
	
	.ui-state-active, .ui-widget-content .ui-state-active {
		background: #38ff00;
		border: 0px;
		color: #000;
		transition: all 0.25s linear;
		-webkit-transition: all 0.25s linear;
	}
	
	th, td {
		
		width: calc(100% / 10); 
		text-align: center;
		padding: 1px 5px;
	}
	th {
		span {
			color: #858585; 
			font-weight: 400;
		}
	}
	.calendar {
		
		margin-bottom: 40px;
	}
	
	&-box {
		
		font-size: 16px;
		
		h4 {
		
			font-size: 28px;
			font-weight: bold;
			color: #000;
		}
		
		
		
		&-separator {
		
			width: 80px;
			margin: 0 auto 30px auto;
			padding-top: 30px;
			border-bottom: 2px #000 solid;
		}
	}
	
	
}

.hasDatepicker {
	text-align: center;
	display: inline-block;
}

a.read-more {
	color: #5eff00 !important;
	font-weight: bold;
	display:block;
	padding-top: 10px;
	text-decoration: none;
	padding-left: 15px;
	padding-right: 15px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	
	
	&:hover, &:focus {
		padding-left: 25px;
		padding-right: 5px;
	}
}
.sidebar-short-desc {
	text-align: left;
	font-weight: 600;
	margin-bottom: 150px;
}
.sidebar-box {
	h4 {
		text-transform: uppercase;
	}
}
.box-wystawa {
	text-decoration: none !important;
	&:hover, &:focus {
		text-decoration: none;
	}
}
 