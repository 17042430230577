html, body {
	width: 100%;
	height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 50px; 
}


p {
	margin: 0 0 30px;
}


.error404 { 
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: #000;
	padding-top: 10vh;
	
	.logo {
		margin-bottom: 3em;
	}
	
	p {
		font-size: 26px;
	}
}



.list-inline {
	margin-bottom: 0;
}