.footer {
	padding-top: 120px;
	
	
	.address {
		font-weight: 700;
		margin-top: calc(50px + 3em);
		line-height: (24/18);
		font-size: 18px;
	}
	
	.title-salons {
		margin-bottom: 3em;
		text-transform: uppercase;
	}
	
	.copyright {
		margin-top: 0px;
		margin-bottom: 20px;
		color: #bbbbbb;
		
		.link-privacy-policy {
			margin-left: 3em;
		}
		
		a {
			color: #bbb;
		}
		
		
		.dev,
		.link-privacy-policy {
			padding-left: 15px;
			float: none !important;
			margin-left: 0;
		}
	}
	
	a {
		color: #333;
	}
	.back-to-top {
		color: #000;
		font-size: 30px;
		font-weight: 600;
		padding-bottom: 60px;
		img {
			padding-top: 15px;
			padding-bottom: 15px;
			transition: all 0.25s linear;
			-webkit-transition: all 0.25s linear;		
		}
		@media(min-width: $screen-sm)
		{
			&:hover, &:focus {
				img {
					padding-top: 5px;
					padding-bottom: 25px;
				}
				
			}
		}
	}
	.foot-content {
		    padding-top: 60px;
	}


}

.box-salons {
	
	margin-bottom: 2em;
	
	.img {
		line-height: 60px;
		> img {
			display: inline-block;
		}
	}
	
	&, .title {
		line-height: (24/14);
	}
	
	.title {
		font-weight: 700;
		margin-bottom: 0;
		margin-top: 0;
	}
}
.date-more-info .close {
	color: #fff;
	opacity: 1;
}
.ui-datepicker td a, .ui-datepicker td span {
	padding: 0.3em;
	border-radius: 50%;
}
.footer-box {
	color: #9e9e9e;
}
.bip-margin {
	margin-bottom: 20px;
}