
.section-cars-filters {
	background-color: #434343;
	color: #fff;
	
	.row-title {
		font-size: 14px;
		margin-top: 2em;
		margin-bottom: 2em;
		
		font-weight: 700;
		
		.title {
			margin-right: 3em;
		}
		
		.btn {
			&:hover,
			&:focus {
				color: #fff;
			}
		}
		
		.btn-reset {
			font-weight: 700;
			> .fa {
				margin-right: 1em;
			}
		}
		
		.fa {
			font-size: 16px;
		    vertical-align: text-top;
		}
		
		.list-controls {
			color: rgba(#fff, 0.5);
			margin-bottom: 0;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}	
}


.row-filters {
	line-height: 34px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-top: 10px;
	padding-bottom: 10px;
	
	.w-submit {
		text-align: center;
		@include media-up(md) {
			text-align: right;
		}
	}
}
	
.section-cars {
	background-color: #434343;
	color: #fff;
	padding-bottom: 85px;
	&.section {
		padding-top: 85px;
	}
	
	h2 {
		margin-bottom: 1.8em;
	}
	
	.grid {
		@include media-up(sm) {
			@include make-row();
		}
		
		.item {
			@include make-xs-column(12);
			@include make-sm-column(4);
			@include make-md-column(3);
			@include make-lg-column(2);
			
			padding-left: 1px;
			padding-right: 1px;
			padding-bottom: 40px;
		}
	}
	
	
	.list {
		@include media-up(sm) {
			@include make-row();
		}
		
		.item {
			@include make-xs-column(12);
			
			@include make-sm-column(10);
			@include make-sm-column-offset(1);
			
			@include make-md-column(8);
			@include make-md-column-offset(2);
			
			@include make-lg-column(6);
			@include make-lg-column-offset(0);
			
			padding-left: 1px;
			padding-right: 1px;
			padding-bottom: 1px;
			@include media(xs) {
				padding-bottom: 40px;
			}
		}
	}
}


.box-car {
	color: #1d1d1d;
	position: relative;
	z-index: 1;
	box-shadow: 0 0 40px 10px rgba(#000, 0.0);
	transition: all 200ms ease-in-out;
	background-color: #fff;
    position: relative;
    @include clearfix;
    
    .content {
	    .list & {
		    @include make-xs-column(12);
		    padding-top: 15px;
		 }
		.title-wrapper {
			@include make-xs-column(12);
		}
		.list-wrapper {
			@include make-xs-column(12);
		}
	}
	
	.image {
		img {
			width: 100%;
			.grid & {
				@include media(lg) { min-height: 144px; }
				@include media(md) { min-height: 180px; }
				@include media(sm) { min-height: 186px; }
			}
		}
	}
    
    .grid & {
	    min-height: 408px;
	    .content {
// 		    padding: 7px 18px 17px;

		}
    }

	.list & {
		
		@include media(sm) {
			min-height: 156px;
		}
		@include media(md) {
			min-height: 161px;
		}
		
		@include media(lg) {
			min-height: 146px;
		}
		
		
		.image {
			@include make-xs-column(12);
			@include make-sm-column(4);
			padding-left: 0;
			padding-right: 0;
		}
		.content {
			@include make-sm-column(8);

			.title-wrapper {
				@include make-sm-column(6);
			}
			.list-wrapper {
				@include make-sm-column(6);

			}
		}
	}

	.content {
		background-color: #ffffff;
		line-height: 1.2;
		@include media(xs) {
			
		}
		
		.title {
			font-weight: 700;
			line-height: 1.2;
			min-height: 1.2em * 5;
			.list & { margin-top: 0; }
		}
		.list-icons {
			margin-top: 1em;
			margin-bottom: 0;
			
			> li {
				+ li {
					margin-left: -4px;
				}
			}
			font-size: 18px;
			color: #c49a6c;
		}
		
		.price {
			margin-top: 0.5em;
		}
	}
	.btn-add {
		position: absolute;
		right: 0;
		bottom: 0;
		
		
// 		visibility: hidden;


	}
	
	&:hover {
		box-shadow: 0 0 40px 10px rgba(#000, 0.40);
		z-index: 1000;
		.btn-add {
			visibility: visible;
		}
	}
}


.section-single-car {
	background-color: #434343;
	padding-bottom: 180px;

	
	> .container {
		background-color: #FFF;
	}
	
	.basic,
	.features,
	.content {
		padding: 30px 40px;
	}
	
	.basic {
		.price {
			margin-bottom: 2em;
		}
	}

	
	.features {
		margin-left: -15px;
		margin-right: -15px;
		
		> ul {
			column-count: 2;
// 			line-height: (30 / 14);
			> li {
				margin-bottom: 10px;
			}
		}
	}
	
	
	.content {
		border-top: 1px solid #ebebeb;
	}
	
	.table {
		>tbody {
			>tr {
				>td {
					border-top: 0;
					padding: 5px;

					&:first-child {
						border-right: 1px solid #ebebeb;
						width: (100% / 2);
						padding-left: 0;
					}
					&:last-child {
						font-weight: 700;
						padding-left: 3em;
					}
				}
			}
		}
	}
}




.section-compare {
	background-color: #434343;
	padding-bottom: 180px;
	
	.table {
		
		&.table-items-4 {
			>thead>tr>td {
				&.heading {
					width: 20%;
				}
				&.column {
					width: ((100% - 20%) / 4);
				}
			}
		}
		&.table-items-3 {
			>thead>tr>td {
				&.heading {
					width: 20%;
				}
				&.column {
					width: ((100% - 20%) / 3);
				}
			}
		}
		&.table-items-2 {
			>thead>tr>td {
				&.heading {
					width: 20%;
				}
				&.column {
					width: ((100% - 20%) / 2);
				}
			}
		}
		
		>thead {
			>tr {
				>td {
					padding: 0;
					&.heading {
// 						width: 20%;
					}
					&.column {
// 						width: 20%;
						@include media(xs) { min-width: 190px; }
						vertical-align: bottom;
					}
				}
			}
		}
		
		>tbody {
			background-color: #fff;
			
			>tr {
				>td {
					padding: 5px 21px;
					white-space: normal;
					+ td {
						border-left: 2px solid #000;
						font-weight: 700;
					}
				}
				
				&.title {
					>td {
						border-top: 0;
						&.heading {
							font-weight: 700;
							vertical-align: middle;
						}
						&.column {
							font-weight: 700;
							line-height: 1.2;
							min-height: 6em;
						}
					}
				}
				&.price {
					>td {
						vertical-align: middle;
						height: 60px;
						
						&.heading {
							font-weight: 700;
						}
						&.column {
							font-size: 18px;	
						}
						
					}
				}
				
			}
		}
	}
}

.row-car-top {
	background-color: #434343;
	padding-top: 60px;
	padding-bottom: 30px;
	
	&, a {
		color: #fff;
	}
	
	a {
		
		text-transform: uppercase;
		font-weight: 700;
		&:hover,
		&:focus {
			text-decoration: none;
		}
		
		&.back {
			.fa {
				margin-right: 1em;
			}
		}
	}
}
	


.compare {
	.no-localstorage & { display: none; }
}


.offers-clipboard {
	.no-localstorage & { display: none; }
	
	@include media(xs) {
		right: -100%;
		transition: all 300ms ease-in-out;
	}
	&.xs-show {
		right: 0;
	}
	
	position: fixed;
		right: 0;
		top: 43vh;
	@include media(xs) { top: 110px; }
	z-index: 1100;
// 		width: 300px;

	color: #fff;
	
	.card-toggle {
		cursor: pointer;
	}
	
	.card-btn,
	.card-content {
		position: absolute;
		right: 0;
		transition: all 300ms ease-in-out;
	}
	
	.card-btn {
		top: 55px;
		display: inline-block;
		padding: 15px 30px;
		background: #f2dfb6;
		color: #000;
		box-shadow: 0 0 20px 3px rgba(#000000, .2);
		transform: translateX(55px) rotate(90deg);
		min-width: 160px;
		text-align: center;
		font-weight: 700;
		text-transform: uppercase;
		&:hover {
			background-color: #dabd80;
				
		}
		
	}
	.card-content {
		transform: translateX(100%);
		width: 320px;
		background-color: #383838;
		.card-title {
			padding: 15px 20px;
			font-weight: 700;
			text-transform: uppercase;
		}
		.card-inside {
			padding: 0 15px 15px;
		}
	}

	&.card-show {
		.card-content {
			transform: translateX(0%);
		}
		
		.card-btn {
			transform: translateX(100%) rotate(90deg);
		}
	}
	

}


.compare-list {
	font-size: 12px;
	padding-left: 1.3em;
	
	> li {
		margin-bottom: 10px;
		
		> span {
			display: inline-block;
			vertical-align: top;
		}
		
		.title {
			width: 180px;
			padding-right: 1.5em;
		}
		.price {
			width: 80px;
			padding-right: 1.5em;
			text-align: right;
		} 
		.remove {
			cursor: pointer;
		}
	}
}









 


.menu-services {
	background-color: #383838;
	color: #fff;
	
}


.box-services {
	@include make-row();
	text-align: center;
	
	padding-top: 90px;
	min-height: 300px;
	@include media-down(sm) {
		padding-top: 20px;
		min-height: 200px;
	}
	
	display: block;
	color: #fff;
	
	&, .img {
		transition: all 300ms ease-in-out;
	}
	
	.img {
		margin-bottom: 20px;
		opacity: 0.2;
	}
	.title {
		font-size: 18px;
		font-weight: 700;
	}

	&:hover {
		background-color: #272727;
		color: #fff;
		text-decoration: none;
		
		.img {
			opacity: 1;
		}
	}
}

	.row-list {
		list-style: none;
		padding: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		min-height: 240px;
		.title {
			margin: 0;
		}
	}

.section-contact {

	.title {
		margin-bottom: 30px;
	}

	.sub-title {
		padding-right: 1em;
		@include media-down(sm) {
			margin-bottom: 30px;
		}
		&:after {
			content: '';
			display: none;
			@include media-up(md) {
				display: block;
			}
			width: 50vw;
			
			@include media(lg) { height: 292px; }
			@include media(md) { height: 243px; }
			
			position: absolute;
				top: 0;
				right: -15px;
			z-index: -1;
			
			background-color: #eaeaea;
		}
	}
	

}

	.safari.desktop  {
		.fix {
			@include media-up(md) {
				width: 16%;
			}
		}
	}


.box-select-mark {
	display: block;
	text-align: center;
	font-size: 18px;
	color: rgba(#404040, 0.2);
	
	.image {
		
		opacity: 0.2;
		line-height: 45px;
		margin-bottom: 0.7em;
	}
	.text {
		display: block;
		line-height: 1;
		min-height: 2em;
	}
	
	.active &,
	&:hover,
	&:focus {
		color: rgba(#404040, 1);
		text-decoration: none;
		
		.image {
			opacity: 1;	
		}
	}
	
}
.featured-exhibition-title2 {
	font-size: 36px;
    font-weight: 600; 
	line-height: 36px;	 
	padding-left: 20px;
    z-index: 9999;
	min-height: 70px;
	a {
		color: #000;
		text-decoration: none;
	}
	.bigger {
		font-size: 45px;
	}
}
 

.featured-exhibition-background {
 
	margin-bottom: 0px;
}



.box-person {
	&-big {
		.picture {
			@include make-row();
			position: relative;
			overflow: hidden;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba( #000, 0 );
				transition: background-color 300ms ease-in-out;
			}
			
			.btn {
				position: absolute;
				bottom: 0;
				transform: translateY(100%);
				z-index: 1;
				transition: transform 300ms ease-in-out;
			}

			
			&:hover,
			&.hover {
				&:before { background-color: rgba( #000, 0.2 ); }
				.btn { transform: translateY(0); }
			}
		}
		
		font-size: 16px;
		margin-bottom: 2.5em;
		
		.title {
			font-size: 1em;
			margin-top: 1.7em;
			margin-bottom: 1em;
		}
		.position {
			margin-bottom: 1em;
		}
		.phone {
			font-size: 1.1em;
			font-weight: 700;
				margin-bottom: 1em;
			
			.fa {
				vertical-align: middle;
				font-size: 0.85em;
				margin-right: 1em;
			}
		}
		.email {
			font-size: 0.85em;
		}
		
		a {
			color: #1d1d1d;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
	
	&-small {
		@include clearfix;
		.picture {
			float: left;
			margin-right: 40px;
			
			.img {
				width: 80px;
			}
		}
		
		.info {
			float: left;
			width: 220px;
			line-height: (16/14);
		}
		
		.title {
			margin-top: 0.5em;
		}
		.position {
			padding-right: 1em;
		}
		
		.btn {
			margin-top: 20px;
		}
	}
}


.section-contact-head {
	padding-top: 100px;
	margin-bottom: 30px;
	
	h1 {
		margin-top: 0;
	}
	.address {
		font-size: 16px;
		line-height: ( 24 / 16 );
		a {
			color: #000 !important;
		}
	}
	
	.w-button {
		text-align: center;
		@include media-up( sm ) {
			text-align: right;
		}
	}
}

.section-maps {
	min-height: 50vh;
}



.section-offers {
	padding-top: 50px;
	padding-bottom: 50px;
	
	h2 {
		margin-bottom: 1.5em;
	}
}

.box-offer {
	display: block;
	margin-bottom: 30px;
	transition: all 200ms ease-in-out;
	&:hover,
	&:focus {
		opacity: 0.8;
	}
}

.tab-offer {
	h2 {
		margin-bottom: 1.5em;
	}
}


.section-brands {
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	margin-bottom: 70px;
	overflow: hidden;
	
	.item {
		min-height: 200px;
		+ .item {
			@include media-up(sm) { border-left: 1px solid #ebebeb; }
		}
	}	
}

.box-brand {
	min-height: 200px;
	display: block;
	text-align: center;
	font-size: 18px;
	color: #404040;
	padding-top: 30px;
	margin-left: -15px;
	margin-right: -15px;
	
	.image {
		line-height: 100px;
	}
	
	.svg {
		display: inline-block;
		vertical-align: middle;
	}
	
	.svg {
		fill: #000;
	}
	
	&, .svg {
		transition: all 200ms ease-in-out;
	}
	
	&:hover,
	&:focus {
		background-color: #dfdfdf;
		text-decoration: none;
		color: #fff;
		.svg {
			fill: #fff;
		}
	}
}

.section-search {
	min-height: 157px;
	background-color: #000;
	color: #fff;
	font-size: 16px;
	
	
	&, .row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
}



.section-service-list {
	background-color: #434343;
	color: #fff;
	padding-top: 80px;
	padding-bottom: 100px;
	
	h2 {
		margin-bottom: 2em;
	}
}



.box-service-list {
	+ .box-service-list {
		margin-top: 100px;
	}
	
	
	.img {
		@include media(xs) { margin-bottom: 30px; }
	}
	h4 {
		margin-top: 0;
		margin-bottom: 1.5em;
	}
}

#collapseService {
	position: relative;
	top: -29px;
}
.box-collapse {
	display: inline-block;
	min-width: 249px;
	text-transform: uppercase;
	background-color: #fff;
	padding: 20px;
	line-height: 2;
	font-weight: 700;
	a {
		color: #7f7f7f;
		&:hover,
		&:focus {
			color: #000000;
		}
	}
}
.wystawa-item {
	min-height: 350px;
	h4 {
		min-height: 40px;
	}
}
.wystawa-image {
	width: 100%;
	height: 192px;
	background-position: top left;
	background-size: cover;
	position: relative;
}

.wystawa-image:after, .wystawa-image:before {
	bottom: 0%;
	left: 63%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.wystawa-image:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #fff;
	border-width: 40px;
	margin-left: -40px;
}
.wystawa-image:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #fff;
	border-width: 46px;
	margin-left: -46px;
}

.featured-exhibition-background:after, .featured-exhibition-background:before {
	bottom: 0%;
	left: 63%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.featured-exhibition-background:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #fff;
	border-width: 70px;
	margin-left: -70px;
	
}

.featured-exhibition-background:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #fff;
	border-width: 76px;
	margin-left: -76px; 
}
@media(max-width: $screen-sm-max)
{
	.featured-exhibition-background:after {
		border-width: 40px;
		margin-left: -40px;
		
	}
	.featured-exhibition-background:before {
		border-width: 46px;
		margin-left: -46px;
	}
}

.team-title {
	font-size: 36px;
	font-weight: 600;
	padding-bottom: 60px;
}
.team-list {
	padding-left: 40px;
		
}
.ui-datepicker-prev:before {
	content: "<<";
	position: absolute;
	width:20px;
	height: 16px;
	font-weight: 600;
	font-size: 16px;
	top: 5px;
	left: 5px;
}  
.ui-datepicker-next:before {
	content: ">>";
	position: absolute;
	width:20px;
	height: 16px;
	font-weight: 600;
	font-size: 16px;
	top: 5px;
	right: 5px;
}
.team-row {
	min-height: 125px;
}

@media(min-width: $screen-md-max)
{
	.wystawa-image {
		height: 220px;
		min-height: 220px;
	}
	.featured-exhibition-background {
		height: 475px;
		min-height: 475px;
		background-size: cover;
    width: 100%;
    font-size: 46px;
    font-weight: 700;
    line-height: 48px;
    position: relative;
	}
}

@media(max-width: $screen-md-max)
{
	.wystawa-image, .featured-exhibition-background {
		height: 250px;
		min-height: 250px;
	}
}
@media(max-width: $screen-sm-max)
{
	.wystawa-image, .featured-exhibition-background {
		height: 300px;
		min-height: 300px;
	}
}

@media(max-width: $screen-xs-max)
{
	.wystawa-image, .featured-exhibition-background {
		height: 220px;
		min-height: 220px;
	}
}
@media(max-width: $screen-md)
{
	 
	.navbar-nav { 
		margin: 0px;
		
	}
	.navbar-collapse {
		float: left !important;
		padding: 0px;
	}
	.gm  {
		    padding-top: 25px;
	}
	.team-list {
		padding-left: 0px;		
	}
	.container-article-title{
		position: relative !important;
		padding-top: 40px !important;
		font-size: 36px;
		top: 0px !important;
		left: 0px !important;
		font-weight: 600;
		padding-bottom: 40px;
	}
	.container-article {
		margin-top: 60px;
		
	}
	body {
		padding-top: 30px;
	}
	.vertical-align {
		display: block !important;
	 
	}
	.featured-exhibition-title {
		left: 0px !important;
		padding-left: 15px;
		padding-right: 15px;
		
	}
	.flex-control-nav, .flex-direction-nav {
		display: none !important;
	}

}
 
@media(max-width: $screen-sm)
{
	 
	 
	.team-avatar {
		padding: 0px;
		p {
			margin: 0px;
		}
	}
	.team-email {
		font-size: 12px;
	}
}


.home-slider {
	border: 0px;
	border-radius: 0px;
	position: relative;
	  .slides {
		> .slide {
			display: none; 
			-webkit-backface-visibility: hidden;
		}
	} 
		
}
.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}
.vertical-align {
    display: flex;
    align-items: center;
}
.datepicker-container {
	position: relative;
	
	.date-more-info {
		position: absolute;
		width: 600px;
		padding: 15px;
		background: rgba(0,0,0, 0.8);
		color: #fff;
		font-size: 14px;
		left: 100%;
		top: 0px;
		z-index: 99999;
		.content {
			text-align: left;
			padding-top: 15px;
			p {
				margin: 0px 10px;
			}
			a {
				color: #fff !important;
			}
			.popup-item:last-child hr{
				display: none;
			}
		}
		.close {
			span {
				font-family: 'cob';
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
	
}
.Highlighted a{
	   background-color : #000 !important;
	   background-image :none !important;
	   color: White !important;
	   font-weight:bold !important;
	   font-size: 12pt;
}
.search-form {
	padding-top: 110px;
	text-align: center;
	padding-bottom: 0px;
	padding-left: 10px; 
	input {
		width: 100px;
	}
}
input[type="submit"] {
	background: transparent;
    border: 0px;
    padding: 5px 15px;
    color: #000;
	font-weight: 700;
	
}
input[type="email"], input[type="text"],  input[type="search"] {
    border: 0px;
    border-bottom: 2px solid #000;
	box-shadow: none;
	outline: none;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
}

.mailerlite-form-field input[type="email"] {
    border: 2px solid #000;
	box-shadow: none;
	outline: none;
	width: 80%;
}

.search_button {
	background: transparent;
	border: 0px;
	box-shadow: none;
	font-size: 18px;
}
.search-no-result {
	font-size: 36px;
    font-weight: 600;
    padding-bottom: 60px;
}

.dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
	color: #000;
	background-color: #fff;
}
.button-alfabet {
	color: #9e9e9e; 
	padding: 5px 15px;
	font-size: 16px;
	font-weight: 500;
	&:hover, &:focus {
		color: #5eff00;
		text-decoration: none;
	}
}
.main-content  .box{
	margin-bottom: 0px !important;
	padding-left: 15px;
	padding-right: 15px;
	    line-height: 18px;
	.separator {
		border-bottom: 1px solid #000;
		width: 100%;
		height: 1px;
	}
}
.separator {
	margin-top: 30px !important;
	border-bottom: 1px solid #000 !important;
}
.image-caption {
	color: #858585;
	font-size: 12px;
	padding-top: 10px;
}
.archiwum-dates {
	color: #858585;
	position:relative;
	margin-top: -50px; 
	padding-bottom: 50px;
	a {
		font-size: 14px;
		color: #858585;
	}
	
}