// Glyphicons font path
$icon-font-path: "../fonts/";
$font-path: "../fonts";
$img: "../images";

$fa-font-path: "../../bower_components/font-awesome/fonts/";


// Colors
// $brand-primary:         #27ae60;
$nav-link-hover-bg: transparent;

$font-family-base: 'Tw Cen MT';

 
$navbar-height: 170px;
$navbar-default-link-active-bg: transparent;


$headings-font-weight: 700;



$font-size-h1: 54px;
$font-size-h2: 40px;
$font-size-h3: 26px;


$dropdown-link-color: #fff;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: transparent;


$grid-float-breakpoint: 992px; // $screen-md-min;